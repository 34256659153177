import Auth from '@aws-amplify/auth'
import {
  Avatar,
  Dropdown,
  Icon,
  Layout,
  Menu,
  Tag,
} from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import amplify from '../../assets/conf/amplify.json'

const Header = ({ onStateChange }) => {
  const [user, setUser] = useState()

  const getCurrentUser = useCallback(
    async () => {
      const newUser = await Auth.currentAuthenticatedUser()
      setUser(newUser)
    },
    [],
  )

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  const handleSignout = async () => {
    await Auth.signOut()
    onStateChange('signedOut')
  }

  const renderAvatar = () => {
    if (user && user.attributes) {
      const { attributes } = user

      if (attributes.given_name && attributes.family_name) {
        return (
          <Avatar style={{ cursor: 'pointer' }}>
            {`${attributes.given_name[0]}${attributes.family_name[0]}`}
          </Avatar>
        )
      }
    }

    return (
      <Avatar icon="user" style={{ cursor: 'pointer' }} />
    )
  }

  const renderDropdownMenu = () => (
    <Menu>
      <Menu.Item key="logout" onClick={handleSignout}>
        <Icon type="logout" />
        <span>
          <FormattedMessage id="comp.layout.header.logout" />
        </span>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout.Header
      className="fixed flex"
      style={{
        backgroundColor: '#f8f9fa',
        boxShadow: '0 1px 4px rgba(0,21,41,.08)',
        marginLeft: -200,
        width: '100%',
        zIndex: 20,
      }}
    >
      <span
        className="bold caps"
        style={{ color: 'red', marginLeft: 184 }}
      >
        <FormattedMessage id="comp.layout.header.admin" />
        {amplify.stage !== 'prod' && (
          <Tag className="ml1" color="gold">
            PREVIEW
          </Tag>
        )}
      </span>
      <div className="ml-auto">
        <Dropdown
          overlay={renderDropdownMenu()}
          trigger={['click']}
        >
          {renderAvatar()}
        </Dropdown>
      </div>
    </Layout.Header>
  )
}

Header.propTypes = {
  onStateChange: PropTypes.func.isRequired,
}

export default Header
