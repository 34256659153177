import React from 'react'
import ReactDOM from 'react-dom'

import 'antd/dist/antd.css'
import 'basscss-flexbox/css/flexbox.css'
import 'basscss-layout/css/layout.css'
import 'basscss-margin/css/margin.css'
import 'basscss-padding/css/padding.css'
import 'basscss-position/css/position.css'
import 'basscss-typography/css/typography.css'

import I18nProvider from './components/I18nProvider'

import App from './App'

ReactDOM.render(
  <I18nProvider>
    <App />
  </I18nProvider>,
  document.getElementById('root'),
)
