export const getFormOneColumnLayout = () => ({
  labelCol: {
    sm: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 20 },
  },
})

export const getFormTwoColumnsLayout = () => ({
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
})
