import Auth from '@aws-amplify/auth'
import {
  Button,
  Form,
  Icon,
  Input,
  message,
} from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

class SigninForm extends Component {
  state = {
    loading: false,
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { changeState, form, intl } = this.props
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })

        try {
          const { username, password } = values
          const user = await Auth.signIn(username, password)

          if (user.challengeName === 'SMS_MFA') {
            changeState('confirmSignIn', user)
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            changeState('requireNewPassword', user)
          } else {
            const data = await Auth.verifiedContact(user)

            if (data.verified) {
              changeState('signedIn', user)
            } else {
              changeState('verifyContact', Object.assign(user, data))
            }
          }
        } catch (ex) {
          form.resetFields(['password'])
          this.setState({ loading: false })
          message.warn(intl.formatMessage({ id: 'cont.signin.login-failed' }))
        }
      }
    })
  }

  render() {
    const { form, intl } = this.props
    const { loading } = this.state

    const usernameInput = () => (
      <Input
        placeholder={intl.formatMessage({ id: 'common.username' })}
        prefix={<Icon type="user" />}
        size="large"
      />
    )
    const passwordInput = () => (
      <Input
        placeholder={intl.formatMessage({ id: 'common.password' })}
        prefix={<Icon type="lock" />}
        size="large"
        type="password"
      />
    )

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {form.getFieldDecorator('username', {
            rules: [{ required: true }],
          })(usernameInput())}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('password', {
            rules: [{ required: true }],
          })(passwordInput())}
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            icon="login"
            loading={loading}
            size="large"
            style={{ width: '100%' }}
            type="primary"
          >
            <span>
              <FormattedMessage id="cont.signin.signin" />
            </span>
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

SigninForm.propTypes = {
  changeState: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
}

export default injectIntl(Form.create()(SigninForm))
