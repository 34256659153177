import { Link, Location } from '@reach/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon, Layout, Menu } from 'antd'

import LogoLight from '../../assets/img/logo-light.svg'

import nav from './nav'

export default () => (
  <Location>
    {({ location }) => (
      <Layout.Sider
        className="fixed overflow-auto"
        style={{
          boxShadow: '2px 0 6px rgba(0,21,41,.35)',
          height: '100vh',
          zIndex: 21,
        }}
        width={200}
      >
        <div className="py2">
          <Link to="/">
            <img
              alt="Raffskie Admin"
              src={LogoLight}
              width="80%"
              style={{ marginLeft: '1.25rem' }}
            />
          </Link>
        </div>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          theme="dark"
        >
          {nav.map((n) => (
            <Menu.Item key={n.route}>
              <Link to={n.route}>
                <Icon type={n.icon} />
                <span>
                  <FormattedMessage id={n.messageId} />
                </span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
    )}
  </Location>
)
