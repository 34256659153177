import Amplify from '@aws-amplify/core'
import { withAuthenticator } from 'aws-amplify-react'
import PropTypes from 'prop-types'
import React from 'react'

import amplify from './assets/conf/amplify.json'
import Layout from './components/Layout'
import Signin from './containers/Signin'

Amplify.configure(amplify)

// onStateChange is injected by withAuthenticator
const App = ({ onStateChange }) => (
  <Layout onStateChange={onStateChange} />
)

App.propTypes = {
  onStateChange: PropTypes.func.isRequired,
}

export default withAuthenticator(App, false, [
  <Signin />,
])
