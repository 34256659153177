import moment from 'moment'
import PropTypes from 'prop-types'
import React, { createContext, useContext } from 'react'
import { IntlProvider } from 'react-intl'

// load our locales
import enUS from '../../assets/locale/en-US.json'
import { LANGUAGE } from '../../constants/localStorage'

const messages = {
  'en-US': enUS,
}

export const I18nContext = createContext({
  locale: 'en-US',
  onLocaleChange: (locale) => {
    window.localStorage.setItem(LANGUAGE, locale)
    moment.locale(locale)
  },
})

const I18nProvider = ({ children }) => {
  // TODO validate this locale is in the locale database
  const { locale } = useContext(I18nContext)
  moment.locale(locale)

  return (
    <IntlProvider
      locale={locale.split('-')[0]}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  )
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default I18nProvider
