import { AuthPiece } from 'aws-amplify-react'
import React from 'react'
import { Card, Col, Row } from 'antd'

import Logo from '../../assets/img/logo.svg'
import PasswordForm from '../../components/Form/PasswordForm'

import SigninForm from './SigninForm'

export default class Signin extends AuthPiece {
  constructor(props) {
    super(props)

    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['signIn', 'requireNewPassword']
  }

  showComponent() {
    const { authData, authState } = this.props
    const requireNewPassword = authState === 'requireNewPassword'

    return (
      <Row
        justify="center"
        style={{
          background: '#f0f2f5',
          minHeight: '100vh',
          paddingTop: '6rem',
        }}
        type="flex"
      >
        <Col>
          <Card
            style={{ width: requireNewPassword ? 600 : 400 }}
            title={(
              <img
                alt="Raffskie Admin"
                src={Logo}
                width={200}
              />
            )}
          >
            {requireNewPassword ? (
              <PasswordForm
                authData={authData}
                changeState={this.changeState}
                newPassword
              />
            ) : (
              <SigninForm
                changeState={this.changeState}
              />
            )}
          </Card>
        </Col>
      </Row>
    )
  }
}
