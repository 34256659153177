import { Link } from '@reach/router'
import { Breadcrumb as AntdBreadcrumb, Icon } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import nav from './nav'

export default () => {
  const getBreadcrumbItem = (path) => {
    if (path) {
      const item = nav.find((n) => n.route.includes(path))

      if (item) {
        return (
          <AntdBreadcrumb.Item key={item.route}>
            <Link to={item.route}>
              <FormattedMessage id={item.messageId} />
            </Link>
          </AntdBreadcrumb.Item>
        )
      }

      return (
        <AntdBreadcrumb.Item key={path}>
          {path}
        </AntdBreadcrumb.Item>
      )
    }

    return (
      <AntdBreadcrumb.Item key="home">
        <Link to="/">
          <Icon type="home" />
        </Link>
      </AntdBreadcrumb.Item>
    )
  }

  // split paths and insert home (empty) at 0
  const { pathname } = window.location
  const paths = pathname.split('/').filter((path) => path)
  paths.splice(0, 0, '')

  return (
    <AntdBreadcrumb className="my2">
      {paths.map((path) => (
        getBreadcrumbItem(path)
      ))}
    </AntdBreadcrumb>
  )
}
