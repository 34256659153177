// reformat exception message from amplify-js api calls,
// which uses Axios library under the hood

export default (error) => {
  const { response } = error

  if (response) {
    const { data } = response

    if (data instanceof Object) {
      return `${data.Message} (${data.Code})`
    }

    return error.message
  }

  return error.message
}
