export default [
  {
    icon: 'gift',
    messageId: 'comp.layout.nav.raffles',
    route: '/raffles',
  },
  {
    icon: 'team',
    messageId: 'comp.layout.nav.sponsors',
    route: '/sponsors',
  },
  {
    icon: 'message',
    messageId: 'comp.layout.nav.suggestions',
    route: '/suggestions',
  },
  {
    icon: 'setting',
    messageId: 'common.settings',
    route: '/settings',
  },
]
