import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'

const SaveButton = ({ loading }) => (
  <Button
    htmlType="submit"
    icon="save"
    loading={loading}
    type="primary"
  >
    <span>
      <FormattedMessage id="common.save" />
    </span>
  </Button>
)

SaveButton.propTypes = {
  loading: PropTypes.bool,
}

SaveButton.defaultProps = {
  loading: false,
}

export default SaveButton
