import { Redirect, Router } from '@reach/router'
import PropTypes from 'prop-types'
import React, { Suspense, lazy } from 'react'
import { Layout as AntdLayout, Spin } from 'antd'

import Breadcrumb from './Breadcrumb'
import Footer from './Footer'
import Header from './Header'
import Sider from './Sider'

const Raffles = lazy(() => import('../../containers/Raffles'))
const Settings = lazy(() => import('../../containers/Settings'))
const Sponsors = lazy(() => import('../../containers/Sponsors'))
const Suggestions = lazy(() => import('../../containers/Suggestions'))

const Layout = ({ onStateChange }) => (
  <AntdLayout style={{ minHeight: '100vh' }}>
    <Sider />
    <AntdLayout style={{ marginLeft: 200 }}>
      <Header onStateChange={onStateChange} />
      <AntdLayout.Content style={{ margin: '0 2rem', paddingTop: '4rem' }}>
        <Breadcrumb />
        <main>
          <Suspense fallback={<Spin />}>
            <Router>
              <Raffles path="/raffles" />
              <Sponsors path="/sponsors" />
              <Suggestions path="/suggestions" />
              <Settings path="/settings" />
              <Redirect from="/" noThrow to="/raffles" />
            </Router>
          </Suspense>
        </main>
      </AntdLayout.Content>
      <Footer />
    </AntdLayout>
  </AntdLayout>
)

Layout.propTypes = {
  onStateChange: PropTypes.func.isRequired,
}

export default Layout
