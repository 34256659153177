import Auth from '@aws-amplify/auth'
import {
  Col,
  Form,
  Icon,
  Input,
  message,
  Row,
} from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import SaveButton from '../../Button/SaveButton'
import getErrorMessage from '../../../utils/error'
import { getFormTwoColumnsLayout } from '../../../utils/form'

class PasswordForm extends Component {
  state = {
    loading: false,
    proposed: undefined,
  }

  handleAfterClose = () => {
    this.setState({
      proposed: undefined,
    })
  }

  handleProposedChange = (event) => {
    const { form } = this.props

    form.resetFields(['confirm'])
    this.setState({ proposed: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { form } = this.props
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })

        try {
          const { authData, changeState, newPassword } = this.props
          if (newPassword) {
            // response to new password challenge
            const { proposed } = values
            const { requiredAttributes } = authData

            const user = await Auth.completeNewPassword(
              authData,
              proposed,
              requiredAttributes,
            )

            if (user.challengeName === 'SMS_MFA') {
              this.changeState('confirmSignIn', user)
            } else if (user.challengeName === 'MFA_SETUP') {
              this.changeState('TOTPSetup', user)
            } else {
              const data = await Auth.verifiedContact(user)

              if (data.verified) {
                changeState('signedIn', user)
              } else {
                changeState('verifyContact', Object.assign(user, data))
              }
            }
          } else {
            // change password
            const { previous, proposed } = values

            const user = await Auth.currentAuthenticatedUser()
            await Auth.changePassword(user, previous, proposed)
            this.setState({ loading: false })
          }
        } catch (ex) {
          this.setState({ loading: false })
          message.warn(getErrorMessage(ex))
        }
      }
    })
  }

  validateConfirmPassword = (rule, value, callback) => {
    const { form } = this.props

    if (value && value !== form.getFieldValue('proposed')) {
      callback('Password does not match')
    }

    callback()
  }

  validateProposedPassword = (rule, value, callback) => {
    if (value) {
      const minLength = value.length >= 8
      const hasNumber = /[0-9]/.test(value)
      const hasUpperCase = /[A-Z]/.test(value)
      const hasLowerCase = /[a-z]/.test(value)

      if (!minLength || !hasNumber || !hasUpperCase || !hasLowerCase) {
        callback('Password does not meet minimum requirement')
      }
    }

    callback()
  }

  render() {
    const { form, newPassword } = this.props
    const { loading, proposed = '' } = this.state

    const { labelCol, wrapperCol } = getFormTwoColumnsLayout()

    const minLength = proposed.length >= 8
    const hasNumber = /[0-9]/.test(proposed)
    const hasUpperCase = /[A-Z]/.test(proposed)
    const hasLowerCase = /[a-z]/.test(proposed)

    const IconMessage = ({ messageId, valid }) => (
      <>
        <Icon
          className="mr1"
          style={{ color: valid ? 'green' : 'red' }}
          type={valid ? 'check' : 'close'}
        />
        <FormattedMessage id={messageId} />
      </>
    )

    return (
      <Form onSubmit={this.handleSubmit}>
        {!newPassword && (
          <Row>
            <Col md={12}>
              <Form.Item
                label={<FormattedMessage id="comp.passwordform.previous" />}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
              >
                {form.getFieldDecorator('previous', {
                  rules: [
                    { required: true },
                  ],
                })((
                  <Input
                    autoComplete="current-password"
                    type="password"
                  />
                ))}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={newPassword ? 24 : 12}>
            <Form.Item
              label={<FormattedMessage id="comp.passwordform.proposed" />}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              {form.getFieldDecorator('proposed', {
                rules: [
                  { required: true },
                  { validator: this.validateProposedPassword },
                ],
              })((
                <Input
                  autoComplete="new-password"
                  onChange={this.handleProposedChange}
                  type="password"
                />
              ))}
            </Form.Item>
          </Col>
          <Col md={newPassword ? 24 : 12}>
            <Form.Item
              label={<FormattedMessage id="comp.passwordform.confirm" />}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              {form.getFieldDecorator('confirm', {
                rules: [
                  { required: true },
                  { validator: this.validateConfirmPassword },
                ],
              })((
                <Input
                  autoComplete="new-password"
                  type="password"
                />
              ))}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <ul className="list-reset">
            <li>
              <IconMessage
                messageId="comp.passwordform.minimum"
                valid={minLength}
              />
            </li>
            <li>
              <IconMessage
                messageId="comp.passwordform.number"
                valid={hasNumber}
              />
            </li>
            <li>
              <IconMessage
                messageId="comp.passwordform.upper"
                valid={hasUpperCase}
              />
            </li>
            <li>
              <IconMessage
                messageId="comp.passwordform.lower"
                valid={hasLowerCase}
              />
            </li>
          </ul>
        </Row>
        <Form.Item className="right">
          <SaveButton loading={loading} />
        </Form.Item>
      </Form>
    )
  }
}

PasswordForm.propTypes = {
  authData: PropTypes.object,
  changeState: PropTypes.func,
  form: PropTypes.object.isRequired,
  newPassword: PropTypes.bool,
}

PasswordForm.defaultProps = {
  authData: undefined,
  changeState: undefined,
  newPassword: false,
}

export default Form.create()(PasswordForm)
